import React from "react";
import { Modal } from "react-bootstrap";
import "./placebet.scoped.css";
import { getProfit } from "../../utils/constants";
export default function PlaceBetModal({
  isOpen,
  onClose,
  selectedEventForBet,
  setSelectedEventForBet,
  handleDefaultValueClick,
  handlePlaceBet,
  timerSeconds,
  isLoading,
}) {
  return (
    <div>
      <div className="ant-modal-root">
        <div className="ant-modal-mask" />
        <div className="ant-modal-wrap">
          <div
            role="dialog"
            aria-modal="true"
            className="ant-modal  gx-text-uppercase"
            style={{
              width: 700,
              transformOrigin: "419px 329px",
            }}
          >
            <div
              tabIndex={0}
              aria-hidden="true"
              style={{
                width: 0,
                height: 0,
                overflow: "hidden",
                outline: "none",
              }}
            />
            <div className="ant-modal-content">
              <button
                type="button"
                aria-label="Close"
                className="ant-modal-close"
              >
                <span className="ant-modal-close-x">
                  <span>
                    <svg
                      stroke="currentColor"
                      fill="none"
                      strokeWidth={0}
                      viewBox="0 0 15 15"
                      className="gx-text-grey gx-bg-transparent"
                      height={25}
                      width={25}
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M11.7816 4.03157C12.0062 3.80702 12.0062 3.44295 11.7816 3.2184C11.5571 2.99385 11.193 2.99385 10.9685 3.2184L7.50005 6.68682L4.03164 3.2184C3.80708 2.99385 3.44301 2.99385 3.21846 3.2184C2.99391 3.44295 2.99391 3.80702 3.21846 4.03157L6.68688 7.49999L3.21846 10.9684C2.99391 11.193 2.99391 11.557 3.21846 11.7816C3.44301 12.0061 3.80708 12.0061 4.03164 11.7816L7.50005 8.31316L10.9685 11.7816C11.193 12.0061 11.5571 12.0061 11.7816 11.7816C12.0062 11.557 12.0062 11.193 11.7816 10.9684L8.31322 7.49999L11.7816 4.03157Z"
                        fill="currentColor"
                      />
                    </svg>
                  </span>
                </span>
              </button>
              <div className="ant-modal-body">
                <div id="betPLaceModal" className="ant-row ant-row-center">
                  <div
                    className="ant-col gx-px-0 gx-py-0 gx-rounded-lg gx-d-lg-none gx-d-block  gx-mx-0 gx-my-2 ant-col-xs-24 ant-col-sm-22"
                    style={{
                      border: "2px solid rgb(139, 128, 0)",
                    }}
                  >
                    <div className="gx-my-1 gx-mx-1">
                      <div className="gx-bg-flex gx-align-items-center gx-fs-xl  gx-px-5 ">
                        <div style={{ gap: 20 }}>
                          <span>{selectedEventForBet?.runnerName} </span>
                          <span>
                            Rate : {selectedEventForBet?.selectedOdd} [
                            {selectedEventForBet?.selectedRate}]
                            <span
                              style={{
                                color: "rgb(255, 255, 255)",
                              }}
                            >
                              ({selectedEventForBet?.type})
                            </span>
                          </span>
                        </div>
                        <span
                          className="gx-bg-grey gx-bg-flex gx-justify-content-center gx-align-items-center gx-rounded-circle gx-font-weight-semi-bold gx-fs-xl gx-text-white gx-text-center"
                          style={{
                            width: 30,
                            height: 30,
                          }}
                        >
                          <span>{timerSeconds}</span>
                        </span>
                      </div>
                      <div
                        className="gx-bg-flex gx-mt-3 gx-justify-content-center gx-align-items-center"
                        style={{ gap: 10 }}
                      >
                        <span>Amount</span>
                        <span>
                          <input
                            type="number"
                            disabled={isLoading}
                            onChange={(e) =>
                              handleDefaultValueClick(e.target.value)
                            }
                            value={selectedEventForBet?.amount}
                            className="ant-input gx-font-weight-semi-bold gx-fs-lg"
                          />
                        </span>
                        <button
                          type="button"
                          onClick={() =>
                            setSelectedEventForBet({
                              ...selectedEventForBet,
                              amount: 0,
                            })
                          }
                          className="ant-btn ant-btn-default gx-text-uppercase gx-bg-yellow gx-text-black"
                        >
                          <span>Clear</span>
                        </button>
                      </div>
                      <div className="gx-text-justify gx-mt-2">
                        {[50, 100, 500, 1000, 5000, 10000, 25000].map(
                          (item) => (
                            <button
                              type="button"
                              disabled={isLoading}
                              onClick={() => handleDefaultValueClick(item)}
                              className="ant-btn ant-btn-default gx-px-2 gx-font-weight-semi-bold gx-text-white gx-bg-grey gx-bg-grey"
                              style={{ margin: 4 }}
                            >
                              {item}
                            </button>
                          )
                        )}

                        <div
                          className="gx-mt-1"
                          style={{
                            display: "grid",
                            gridTemplateColumns: "repeat(2, 1fr)",
                            gap: 1,
                          }}
                        >
                          <button
                            type="button"
                            onClick={onClose}
                            className="ant-btn ant-btn-danger gx-text-uppercase"
                          >
                            <span>Close</span>
                          </button>
                          <button
                            disabled={isLoading}
                            onClick={() => {
                              handlePlaceBet();
                            }}
                            type="button"
                            className="ant-btn ant-btn-default gx-bg-primary gx-text-white gx-text-uppercase  gx-mb-0 gx-px-2"
                          >
                            <span>Done</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="ant-modal-footer">
                <button
                  onClick={onClose}
                  type="button"
                  className="ant-btn ant-btn-default gx-bg-grey gx-text-white gx-border-redius0"
                >
                  <span>Close</span>
                </button>
              </div>
            </div>
            <div
              tabIndex={0}
              aria-hidden="true"
              style={{
                width: 0,
                height: 0,
                overflow: "hidden",
                outline: "none",
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
