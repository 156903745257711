import JSEncrypt from "jsencrypt";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { loginAPI } from "../service/AuthService";
import { PUBLIC_KEY } from "../utils/constants";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { generateHash } from "../service";
import { Center } from "@chakra-ui/react";

function LoginPage() {
  const dispatch = useDispatch();
  const [userName, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setIsLoading] = useState(false);
  const [isUnderAge, setIsUnderAge] = useState(false);
  const login = async (e) => {
    setIsLoading(true);

    let encryptedPassword = new JSEncrypt();
    encryptedPassword.setPublicKey(PUBLIC_KEY);
    encryptedPassword = encryptedPassword.encrypt(password);
    let fp = await generateHash();
    localStorage.setItem("fingerprint", fp);
    var { data, code, message, response, error } = await loginAPI(
      userName.replace("-", ""),
      encryptedPassword
    );
    if (code === 200) {
     
      setIsLoading(false);
      dispatch({
        type: "accountData/setUserData",
        payload: response,
      });
      dispatch({
        type: "accountData/setLoginPopSate",
        payload: false,
      });
      dispatch({
        type: "accountData/setChangePopSate",
        payload: response.resetRequired,
      });
      window.location.href = "/main/dashboard";
    } else if (code == 500) {
      toast.error(response);
      setIsLoading(false);
    } else if (code == 403) {
      setIsLoading(false);
      toast.error(error.description);
    } else if (code == 401) {
      setIsLoading(false);
      toast.error(response);
    } else {
      toast.error("Something went wrong or Account is not active");
    }
  };
  return (
    <div
      className="gx-bg-flex gx-box-shadow gx-bg-grey  gx-justify-content-center gx-align-items-center"
      style={{ width: "100vw", height: "100vh" }}
    >
      <div className="gx-bg-grey-revers" style={{ maxWidth: 600 }}>
        <div className="  gx-bg-flex gx-flex-column ">
          <div className=" gx-w-100">
            <div className="gx-px-5">
              <img src="/assets/images/logo.png" alt="Neature" />
            </div>
          </div>
          <div className="gx-app-login-content gx-w-100">
            <div
              id="basic"
              className="ant-form ant-form-horizontal gx-signin-form gx-form-row0"
            >
              <div className="ant-form-item">
                <div className="ant-row ant-form-item-row">
                  <div className="ant-col ant-form-item-control">
                    <div className="ant-form-item-control-input">
                      <div className="ant-form-item-control-input-content">
                        <input
                          placeholder="USERNAME"
                          name="username"
                          onChange={(e) => setUsername(e.target.value)}
                          value={userName}
                          id="basic_username"
                          aria-required="true"
                          className="ant-input gx-border-redius0"
                          type="text"
                          defaultValue=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="ant-form-item">
                <div className="ant-row ant-form-item-row">
                  <div className="ant-col ant-form-item-control">
                    <div className="ant-form-item-control-input">
                      <div className="ant-form-item-control-input-content">
                        <input
                          type="password"
                          placeholder="Password"
                          name="password"
                          onChange={(e) => setPassword(e.target.value)}
                          id="basic_password"
                          value={password}
                          aria-required="true"
                          className="ant-input gx-border-redius0 gx-text-uppercase"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <button
                type="submit"
                onClick={login}
                className="ant-btn ant-btn-default gx-mb-0 gx-w-100 gx-border-redius0 gx-font-weight-semi-bold gx-fs-lg gx-text-white"
                style={{
                  backgroundColor: "rgb(42, 40, 39)",
                  border: "none",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  position: "relative",
                }}
              >
                <span> </span>
                <span className="gx-px-2">Login </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default LoginPage;
