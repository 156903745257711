import moment from "moment";
import React from "react";

export default function CompleteBetModal({ data, show, setShow }) {
  return (
    <div>
      <div className="ant-modal-root">
        <div className="ant-modal-mask" />
        <div tabIndex={-1} className="ant-modal-wrap">
          <div
            role="dialog"
            aria-labelledby="rc_unique_0"
            aria-modal="true"
            className="ant-modal gx-px-3 "
            style={{ width: 520, transformOrigin: "277px 597px" }}
          >
            <div
              tabIndex={0}
              aria-hidden="true"
              style={{
                width: 0,
                height: 0,
                overflow: "hidden",
                outline: "none",
              }}
            />
            <div className="ant-modal-content">
              <button
                type="button"
                onClick={() => {
                  setShow(!show);
                }}
                aria-label="Close"
                className="ant-modal-close"
              >
                <span className="ant-modal-close-x">
                  <span
                    role="img"
                    aria-label="close"
                    className="anticon anticon-close ant-modal-close-icon"
                  >
                    <svg
                      fillRule="evenodd"
                      viewBox="64 64 896 896"
                      focusable="false"
                      data-icon="close"
                      width="1em"
                      height="1em"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path d="M799.86 166.31c.02 0 .04.02.08.06l57.69 57.7c.04.03.05.05.06.08a.12.12 0 010 .06c0 .03-.02.05-.06.09L569.93 512l287.7 287.7c.04.04.05.06.06.09a.12.12 0 010 .07c0 .02-.02.04-.06.08l-57.7 57.69c-.03.04-.05.05-.07.06a.12.12 0 01-.07 0c-.03 0-.05-.02-.09-.06L512 569.93l-287.7 287.7c-.04.04-.06.05-.09.06a.12.12 0 01-.07 0c-.02 0-.04-.02-.08-.06l-57.69-57.7c-.04-.03-.05-.05-.06-.07a.12.12 0 010-.07c0-.03.02-.05.06-.09L454.07 512l-287.7-287.7c-.04-.04-.05-.06-.06-.09a.12.12 0 010-.07c0-.02.02-.04.06-.08l57.7-57.69c.03-.04.05-.05.07-.06a.12.12 0 01.07 0c.03 0 .05.02.09.06L512 454.07l287.7-287.7c.04-.04.06-.05.09-.06a.12.12 0 01.07 0z" />
                    </svg>
                  </span>
                </span>
              </button>
              <div className="ant-modal-header">
                <div className="ant-modal-title" id="rc_unique_0">
                  COMPLETED FANCY BET
                </div>
              </div>
              <div className="ant-modal-body">
                <div className="gx-bg-flex gx-px-2 gx-justify-content-between gx-bg-grey gx-fs-lg gx-font-weight-semi-bold gx-text-white gx-py-1">
                  <div>COMPLETED FANCY BETS</div>{" "}
                  <div className="gx-text-green">{data?.length}</div>
                </div>
                <div className="ant-table-wrapper gx-w-100 gx-mx-0 gx-my-0">
                  <div className="ant-spin-nested-loading">
                    <div className="ant-spin-container">
                      <div className="ant-table ant-table-small ant-table-bordered ant-table-empty ant-table-ping-right ant-table-scroll-horizontal">
                        <div className="ant-table-container">
                          <div
                            className="ant-table-content"
                            style={{ overflow: "auto hidden" }}
                          >
                            <table
                              style={{
                                width: "auto",
                                minWidth: "100%",
                                tableLayout: "auto",
                              }}
                            >
                              <colgroup />
                              <thead className="ant-table-thead">
                                <tr>
                                  <th className="ant-table-cell">RUNNER</th>
                                  <th className="ant-table-cell">AMOUNT</th>
                                  <th className="ant-table-cell">RUN</th>
                                  <th className="ant-table-cell">RATE</th>
                                  <th className="ant-table-cell">MODE</th>
                                  <th className="ant-table-cell">DATE</th>
                                  <th className="ant-table-cell">RESULTS</th>
                                  <th className="ant-table-cell">P&amp;L</th>
                                </tr>
                              </thead>
                              <tbody className="ant-table-tbody">
                                <tr
                                  aria-hidden="true"
                                  className="ant-table-measure-row"
                                  style={{ height: 0, fontSize: 0 }}
                                >
                                  <td
                                    style={{ padding: 0, border: 0, height: 0 }}
                                  >
                                    <div
                                      style={{ height: 0, overflow: "hidden" }}
                                    >
                                      &nbsp;
                                    </div>
                                  </td>
                                  <td
                                    style={{ padding: 0, border: 0, height: 0 }}
                                  >
                                    <div
                                      style={{ height: 0, overflow: "hidden" }}
                                    >
                                      &nbsp;
                                    </div>
                                  </td>
                                  <td
                                    style={{ padding: 0, border: 0, height: 0 }}
                                  >
                                    <div
                                      style={{ height: 0, overflow: "hidden" }}
                                    >
                                      &nbsp;
                                    </div>
                                  </td>
                                  <td
                                    style={{ padding: 0, border: 0, height: 0 }}
                                  >
                                    <div
                                      style={{ height: 0, overflow: "hidden" }}
                                    >
                                      &nbsp;
                                    </div>
                                  </td>
                                  <td
                                    style={{ padding: 0, border: 0, height: 0 }}
                                  >
                                    <div
                                      style={{ height: 0, overflow: "hidden" }}
                                    >
                                      &nbsp;
                                    </div>
                                  </td>
                                  <td
                                    style={{ padding: 0, border: 0, height: 0 }}
                                  >
                                    <div
                                      style={{ height: 0, overflow: "hidden" }}
                                    >
                                      &nbsp;
                                    </div>
                                  </td>
                                  <td
                                    style={{ padding: 0, border: 0, height: 0 }}
                                  >
                                    <div
                                      style={{ height: 0, overflow: "hidden" }}
                                    >
                                      &nbsp;
                                    </div>
                                  </td>
                                  <td
                                    style={{ padding: 0, border: 0, height: 0 }}
                                  >
                                    <div
                                      style={{ height: 0, overflow: "hidden" }}
                                    >
                                      &nbsp;
                                    </div>
                                  </td>
                                </tr>
                                {data?.length == 0 && (
                                  <tr className="ant-table-placeholder">
                                    <td colSpan={8} className="ant-table-cell">
                                      <div
                                        className="ant-table-expanded-row-fixed"
                                        style={{
                                          width: 294,
                                          position: "sticky",
                                          left: 0,
                                          overflow: "hidden",
                                        }}
                                      >
                                        <div className="ant-empty ant-empty-normal">
                                          <div className="ant-empty-image">
                                            <svg
                                              className="ant-empty-img-simple"
                                              width={64}
                                              height={41}
                                              viewBox="0 0 64 41"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <g
                                                transform="translate(0 1)"
                                                fill="none"
                                                fillRule="evenodd"
                                              >
                                                <ellipse
                                                  className="ant-empty-img-simple-ellipse"
                                                  cx={32}
                                                  cy={33}
                                                  rx={32}
                                                  ry={7}
                                                />
                                                <g
                                                  className="ant-empty-img-simple-g"
                                                  fillRule="nonzero"
                                                >
                                                  <path d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z" />
                                                  <path
                                                    d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z"
                                                    className="ant-empty-img-simple-path"
                                                  />
                                                </g>
                                              </g>
                                            </svg>
                                          </div>
                                          <div className="ant-empty-description">
                                            No data
                                          </div>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                )}
                                {data?.map((bet, index) => (
                                  <tr
                                    data-row-key={0}
                                    className={`ant-table-row ant-table-row-level-0 matchdtails${
                                      bet?.back ? "Yes" : "No"
                                    }Background`}
                                  >
                                    <td className="ant-table-cell">
                                      <span className="gx-text-nowrap gx-text-uppercase gx-font-weight-semi-bold">
                                        {bet?.runnerName}
                                      </span>
                                    </td>
                                    <td className="ant-table-cell">
                                      <span className="gx-text-nowrap gx-text-uppercase gx-font-weight-semi-bold">
                                        {bet?.amount}
                                      </span>
                                    </td>
                                    <td className="ant-table-cell">
                                      <span className="gx-text-nowrap gx-text-uppercase gx-font-weight-semi-bold">
                                        {bet?.lay ? bet?.lay : bet.back}
                                      </span>
                                    </td>
                                    <td className="ant-table-cell">
                                      <span className="gx-text-nowrap gx-text-uppercase gx-font-weight-semi-bold">
                                        {bet?.backRate
                                          ? bet?.backRate
                                          : bet?.layRate}
                                      </span>
                                    </td>
                                    <td className="ant-table-cell">
                                      <span className="gx-text-nowrap gx-text-uppercase gx-font-weight-semi-bold">
                                        {bet?.back ? "YES" : "NO"}
                                      </span>
                                    </td>
                                    <td className="ant-table-cell">
                                      <span className="gx-text-nowrap gx-text-uppercase gx-font-weight-semi-bold">
                                        {moment(bet?.createdAt + "Z").format(
                                          "DD-MM-YYYY HH:mm:ss"
                                        )}
                                      </span>
                                    </td>
                                    <td className="ant-table-cell">
                                      <span className="gx-text-nowrap gx-text-uppercase gx-font-weight-semi-bold">
                                        {bet?.result}
                                      </span>
                                    </td>
                                    <td className="ant-table-cell">
                                      <span className="gx-text-nowrap gx-text-uppercase gx-font-weight-semi-bold">
                                        {bet?.profit}
                                      </span>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="ant-modal-footer">
                <button
                  onClick={() => {
                    setShow(!show);
                  }}
                  type="button"
                  className="ant-btn ant-btn-default gx-bg-grey gx-text-white gx-border-redius0"
                >
                  <span>Close</span>
                </button>
              </div>
            </div>
            <div
              tabIndex={0}
              aria-hidden="true"
              style={{
                width: 0,
                height: 0,
                overflow: "hidden",
                outline: "none",
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
