import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import ReactDOM from "react-dom/client";
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import Matches from "./pages/Matches";
import Rules from "./pages/Rules";
import Ledger from "./pages/Ledger";
import ChangePassword from "./pages/ChangePassword";
import LedgerInfoMatch from "./pages/LedgerMatchInfo";
import BetPlay from "./pages/BetPlay";
import { useDispatch, useSelector } from "react-redux";
import store, { saveState } from "./redux/store";
import { Toaster } from "react-hot-toast";


import Upcoming from "./pages/Upcoming";

import Header from "./pages/components/Header";
import ViewStatement from "./pages/ViewCompleteGames";
import Profile from "./pages/Profile";
import CompleteGames from "./pages/CompleteGames";
import AccountStatement from "./pages/AccountStatement";
import { QueryClient, QueryClientProvider, useQuery } from "react-query";
import "./App.css";
import TeenPatti from "./pages/games/TeenPatti";
import DragonTiger from "./pages/games/DragonTiger";
import Lucky7 from "./pages/games/Lucky7";
import CasinoMyBets from "./pages/games/CasinoMyBet";
import AndarBahar from "./pages/games/Anderbahar";
import MatkaPlay from "./pages/matka/MatkaPlay";
import AmarAkhbarAnthoany from "./pages/games/AmarAkhbarAnthoany";
import FreeGames from "./pages/FreeGames";
import PendingBets from "./pages/PendingBets";
import Games from "./pages/Games";
const queryClient = new QueryClient();
export default function App() {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.account.userData);
  const location = useLocation();

  useEffect(() => {
    store.subscribe(() => {
      saveState(store.getState());
    });
  }, [0]);
  return (
    <div className="App">
      <QueryClientProvider client={queryClient}>
        <Toaster />
        {userData?.token ? (
          <>
            <Header />
            <Routes>
              <Route path="/" element={<Dashboard active="dashboard" />} />
              <Route
                path="/main/dashboard"
                element={<Dashboard active="dashboard" />}
              />
              <Route
                path="/dashboard/casino"
                element={<Dashboard active="casino" />}
              />
              <Route
                exact
                path="/main/matches"
                element={<Matches active="matches" />}
              />
              <Route
                path="/main/pending-bets"
                element={<PendingBets active="rules" />}
              />
              <Route path="/main/rules" element={<Rules active="rules" />} />
              <Route
                path="/main/ledger"
                element={<Ledger active="ledgers" />}
              />
              <Route
                path="/main/match-deatils/:id"
                element={<BetPlay active="matches" />}
              />
              {/*  <Route
              path="/game/soccer/:id"
              element={<BetPlaySoccer active="matches" />}
            />
            <Route
              path="/game/tennis/:id"
              element={<BetPlay active="matches" />}
            /> */}
              <Route
                path="/ledgers-match"
                element={<LedgerInfoMatch active="ledgers" />}
              />
              <Route
                path="/main/changepassword"
                element={<ChangePassword active="change_password" />}
              />
              <Route path="/complete_games" element={<CompleteGames />} />
              <Route
                path="/complete_detail/:id"
                element={<ViewStatement />}
              />
              <Route path="/main/teen9/:id" element={<TeenPatti />} />
              <Route path="/main/dt20/:id" element={<DragonTiger />} />

              <Route path="/main/lucky7/:id" element={<Lucky7 />} />
              <Route path="/main/aaa/:id" element={<AmarAkhbarAnthoany />} />
              <Route path="/casino/casino-bets" element={<CasinoMyBets />} />
              <Route path="/main/casino" element={<Games />} />
              <Route path="/main/ander-bahar" element={<AndarBahar />} />
              <Route path="/main/statement" element={<AccountStatement />} />
              <Route path="/main/profile" element={<Profile />} />
              <Route path="/upcoming" element={<Upcoming />} />
              <Route path="/matka/:id" element={<MatkaPlay />} />
              <Route path="/main/freegame" element={<FreeGames />} />

              {/*  <Route path="/lottery/casino_tables/1" element={<Lottery />} />
              <Route
                path="/lottery/casino_tables/8/matka_games"
                element={<MatkaGames />}
              />
              <Route path="/casinoAddaPage" element={<CasinoAddaPage />} />
              <Route path="/TeenPattiOneDay" element={<Teenpatti_T20 />} />
              <Route path="/lottery/casino_tables/2" element={<DusKaDum />} />
              <Route
                path="/lottery/casino_tables/3"
                element={<DragonTiger />}
              /> */}
            </Routes>
          </>
        ) : (
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/login" element={<Login />} />
          </Routes>
        )}
      </QueryClientProvider>
    </div>
  );
}
